import { FileManager } from '@maggie/cordova/file';
import { Platform } from '@maggie/cordova/platform';

import * as development from './env/env.development.json';
import * as production from './env/env.production.json';
import * as secrets from './env/env.secrets.json';
import * as staging from './env/env.staging.json';
// Preserve type safety on env configuration by importing and assigning types
import * as shared from './env/shared.json';
import type {
  DerivedEnvironmentValues,
  EnvironmentDependentConfig,
  EnvironmentVariables,
  SecretsEnvironmentConfig,
  SharedEnvironmentConfig
} from './types';

const sharedEnv: SharedEnvironmentConfig = shared;
const secretsEnv: SecretsEnvironmentConfig = secrets;
const developmentEnv: EnvironmentDependentConfig = development;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stagingEnv: EnvironmentDependentConfig = staging;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const productionEnv: EnvironmentDependentConfig = production;

const getRuntimeEnv = (environment: typeof process.env.CONFIG_ENV): EnvironmentVariables => {
  if (environment === 'test') {
    return {
      ...sharedEnv,
      ...developmentEnv,
      ...secretsEnv,
      NODE_ENV: 'test'
    };
  }

  return window.__ENV__;
};

export let ENV: DerivedEnvironmentValues &
  EnvironmentDependentConfig &
  SharedEnvironmentConfig &
  SecretsEnvironmentConfig;

export const loadEnv = () => {
  const runtimeEnv = getRuntimeEnv(process.env.CONFIG_ENV || process.env.NODE_ENV);

  const derivedEnvFunctions: DerivedEnvironmentValues = {
    STORE_APP_URL_IOS: '991242619',
    ANDROID_PACKAGE_NAME: 'com.cld.ed',
    THOMAS_DEFAULT_VERSION: '6520c58f802276577bf032f16c4642fa33e274fb',
    CLOUDINARY_URL: 'https://res.cloudinary.com/edapp/',
    CLOUDINARY_VIDEO_FOLDER: 'video/upload/',
    CLOUDINARY_IMAGE_FOLDER: 'image/upload/',
    CLOUDINARY_IMAGE_MOBILE_TRANSFORMATION: 'c_limit,h_850',
    CLOUDINARY_IMAGE_DESKTOP_TRANSFORMATION: 'c_limit,h_2000',
    CLOUDINARY_CDN_URL: 'https://media.edapp.com/',
    SC_WEBVIEW_ANDROID_US: 'https://app.safetyculture.com',
    SC_WEBVIEW_ANDROID_EU: 'https://app.eu.safetyculture.com',
    SC_WEBVIEW_ANDROID_AU: 'https://app.au.safetyculture.com',
    SC_WEBVIEW_ANDROID_SANDPIT: 'https://sandpit-app.safetyculture.com',
    SC_WEBVIEW_IOS_US: 'scweb://app.safetyculture.com',
    SC_WEBVIEW_IOS_EU: 'scweb://app.eu.safetyculture.com',
    SC_WEBVIEW_IOS_AU: 'scweb://app.au.safetyculture.com',
    SC_WEBVIEW_IOS_SANDPIT: 'scweb://sandpit-app.safetyculture.com',
    thomasWeb: () => runtimeEnv.THOMAS + runtimeEnv.THOMAS_PLAYER,
    thomasCordovaApp: () =>
      Platform.get() === 'Android'
        ? `${FileManager.wwwDirectory}thomas/player.html`
        : '/thomas/player.html',
    thomasSCWebview: () => `/static/apps/thomas/player.html`,
    ssoRedirectUrl: (code: string) => `${runtimeEnv.EMILY_API}/sso/${code}`,
    apiUrl: (url: string) => `${runtimeEnv.EMILY_API}/api${url}`,
    // This function will contain the legacy logic to handle asssets that are served from emily
    imageUrl: (key?: string) => {
      if (!key) {
        return '';
      }

      if (key.indexOf('/images') === 0) {
        return `${runtimeEnv.EMILY_API}/${key.slice(1)}`;
      } else if (key.indexOf('https://') === 0) {
        // hack to be compatible with v2 course page
        return key;
      } else if (key.indexOf('file://') === 0) {
        return key;
      } else if (key.indexOf('ionic://') === 0) {
        // This is for local assets to be compatible on ionic webview on iOS
        return key;
      } else if (key.indexOf('scweb://') === 0) {
        return key;
      } else if (key.indexOf('asset://') === 0) {
        return key;
      } else {
        return `${runtimeEnv.CDN}images/${key}`;
      }
    }
  };

  ENV = {
    ...runtimeEnv,
    ...derivedEnvFunctions
  };
};

// This function must run at the root of the file. So we ensure ENV has valid value when javascript loads in the page.
loadEnv();
