import * as React from 'react';

import type { History } from 'history';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import type { ReactAppOrParcel } from 'single-spa-react';

import { SegmentPlugin } from '@edapp/analytics-tracking';
import { initialize } from '@edapp/maggie';
import { ErrorLogger } from '@edapp/monitoring';
import type { RelativeToken } from '@edapp/request';
import type { ThemeSetting } from '@edapp/themes';
import { HostedWebviewUtils } from '@edapp/uxp-webview';

import { Error } from './components/Error';
import { loadMaggieEnvironment } from './env';

type ExtraProps = {
  scHistory: History;
  tokens: RelativeToken[];
  lang: string;
  theme: ThemeSetting;
};

const lifecycles = singleSpaReact<ExtraProps>({
  React,
  ReactDOM,
  loadRootComponent: props =>
    import(/* webpackChunkName: "mfe-learn-app" */ './app').then(({ App }) => () => {
      const { theme, scHistory } = props as ExtraProps;
      return <App scHistory={scHistory} theme={theme} />;
    }),
  errorBoundary: err => {
    ErrorLogger.captureEvent('ErrorBoundary', 'error', { err });
    return <Error />;
  }
});

const bootstrap: ReactAppOrParcel<ExtraProps>['bootstrap'] = async ({ tokens, lang, ...args }) => {
  try {
    await loadMaggieEnvironment();
  } catch (e) {
    HostedWebviewUtils.triggerBootstrapError('load_env_failed');
    // Continue to throw out the error,
    // let the shell application to capture and handle it.
    ErrorLogger.captureException(e, { error_bootstrap: 'load_env_failed' });
    throw e;
  }

  try {
    await initialize(lang, tokens, {
      // Disable default destinations,
      // ensure we can use the custom segment plugin
      // which inherits the Segment instance from the shell application
      destinations: {
        all: { disabled: true }
      },
      plugins: [new SegmentPlugin()]
    });
  } catch (e) {
    HostedWebviewUtils.triggerBootstrapError('initialize_failed');
    ErrorLogger.captureException(e, { error_bootstrap: 'initialize_failed' });
    throw e;
  }

  await lifecycles.bootstrap({ tokens, lang, ...args });
};

const mount: ReactAppOrParcel<ExtraProps>['mount'] = async (...args) => {
  await lifecycles.mount(...args);
};

const unmount: ReactAppOrParcel<ExtraProps>['mount'] = async (...args) => {
  await lifecycles.unmount(...args);
};

export { bootstrap, mount, unmount };
