import { t } from 'i18next';
import { pickBy, uniq } from 'lodash';
import {
  actionChannel,
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import type { ActionFromActionType, DictionaryType } from '@edapp/utils';
import { OfflineAssets } from '@maggie/core/offlineAssets';
import { BackboneActionTypes } from '@maggie/store/backbone/actions';
import { ConfigActionTypes } from '@maggie/store/config/actions';
import type { ConfigActionsMap } from '@maggie/store/config/types';
import {
  CollectionsActionTypes,
  CollectionsActions
} from '@maggie/store/courseware/collections/actions';
import type {
  CollectionActionsUnionType,
  CollectionType
} from '@maggie/store/courseware/collections/types';
import { CourseActionTypes, CourseActions } from '@maggie/store/courseware/courses/actions';
import type {
  CourseActionsUnionType,
  CourseProgressType,
  CourseType
} from '@maggie/store/courseware/courses/types';
import { LessonActionTypes, LessonActions } from '@maggie/store/courseware/lessons/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonAction } from '@maggie/store/courseware/lessons/types';
import type { LessonProgressType, LessonType } from '@maggie/store/courseware/lessons/types';
import type { PlaylistItemType } from '@maggie/store/courseware/playlists/types';
import type { UnlockPayload } from '@maggie/store/courseware/types';
import { ToastActions } from '@maggie/store/toast/actions';
import type { LxStoreState } from '@maggie/store/types';

import { OfflineActionTypes, OfflineActions } from './actions';
import { watchDeleteLessonOffline } from './delete-lesson-sagas';
import { sharedDownloadBuffer } from './download-buffer';
import { handleGetPlaylist } from './handle-get-playlist-sagas';
import type { OfflineActionsMap, OfflineState, OfflineType } from './types';

const PROGRESS_INCREMENT = 5;

// COLLECTIONS
type CourseAction<ActionType extends string> = ActionFromActionType<
  CourseActionsUnionType,
  ActionType
>;
type CollectionAction<ActionType extends string> = ActionFromActionType<
  CollectionActionsUnionType,
  ActionType
>;

function* handleGetIds(lessonId: string) {
  // Get IDs through breadcrumb
  yield put(LessonActions.fetchLessonBreadcrumb(lessonId));
  // wait for the download of the lesson data and progress
  const {
    success,
    failure
  }: {
    success: LessonAction<LessonActionTypes.FETCH_LESSON_BREADCRUMB_SUCCESS>;
    failure: LessonAction<LessonActionTypes.FETCH_LESSON_BREADCRUMB_FAILURE>;
  } = yield race({
    success: take(LessonActionTypes.FETCH_LESSON_BREADCRUMB_SUCCESS),
    failure: take(LessonActionTypes.FETCH_LESSON_BREADCRUMB_FAILURE)
  });
  if (!!failure) {
    yield put(
      ToastActions.showToast(true, t('dialog.error.message', { ns: 'learners-experience' }))
    );
    ErrorLogger.captureEvent('Failed Fetching Offline lesson Breadcrumb', 'error', { failure });
    return;
  }
  return {
    courseId: success.payload.courseId,
    collectionId: success.payload.courseCollectionId,
    playlistId: success.payload.playlistId
  };
}

function* handleGetLessonAndProgress(lessonId: string): any {
  let lesson: LessonType = yield select(
    (state: LxStoreState) => state.courseware.lessons.lessons[lessonId]
  );
  let lessonsProgress = yield select(
    (state: LxStoreState) => state.courseware.lessons.lessonsProgress[lessonId]
  );
  if (!lesson || !lessonsProgress) {
    // Download lesson data and progress
    yield put(LessonActions.fetchLessonWithProgress(lessonId, false));

    // wait for the download of the lesson data and progress
    const {
      success,
      failure
    }: {
      success: {
        lesson: LessonAction<LessonActionTypes.FETCH_LESSON_SUCCESS>;
        lessonProgress: LessonAction<LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS>;
        lessonProgressPrerequisites: LessonAction<
          LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS
        >;
      };
      failure: LessonAction<LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE>;
    } = yield race({
      success: all({
        lesson: take(LessonActionTypes.FETCH_LESSON_SUCCESS),
        lessonProgress: take(LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS),
        lessonProgressPrerequisites: take(
          LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS
        )
      }),
      failure: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE)
    });
    if (!!failure) {
      yield put(
        ToastActions.showToast(true, t('dialog.error.message', { ns: 'learners-experience' }))
      );
      ErrorLogger.captureEvent('Failed Fetching Offline lesson', 'error', { failure });
      return;
    }
    lesson = success.lesson.payload.items[0];

    const lProgress = success.lessonProgress.payload[0];

    const lPrerequistes = success.lessonProgressPrerequisites.payload.reduce((acc, item) => {
      return {
        ...acc,
        [item.lessonId]: item
      };
    }, {});

    lessonsProgress = !!lProgress
      ? {
          ...lPrerequistes,
          [lProgress.lessonId]: lProgress
        }
      : { ...lPrerequistes };
  } else {
    lessonsProgress = { [lessonsProgress.lessonId]: lessonsProgress };
  }

  return { lesson, lessonsProgress };
}

function* handleGetCourseAndProgress(courseId: string) {
  // Get course from redux data or fetch it
  let course: CourseType | undefined = yield select(
    (state: LxStoreState) => state.courseware.courses.courses[courseId]
  );
  let courseProgress: CourseProgressType | undefined = yield select(
    (state: LxStoreState) => state.courseware.courses.coursesProgress[courseId]
  );
  if (!course || !courseProgress) {
    yield put(CourseActions.fetchSyncCourse(courseId));
    const {
      success,
      failure
    }: {
      success: CourseAction<CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS>;
      failure: CourseAction<CourseActionTypes.FETCH_SYNC_COURSE_FAILURE>;
    } = yield race({
      success: take(CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS),
      failure: take(CourseActionTypes.FETCH_SYNC_COURSE_FAILURE)
    });
    if (!!failure) {
      ErrorLogger.captureEvent('Failed Fetching Course of offline lesson', 'error', { failure });
      yield put(
        ToastActions.showToast(true, t('dialog.error.message', { ns: 'learners-experience' }))
      );
      return;
    }
    course = success.payload.courseInfo as CourseType;
    courseProgress = success.payload.courseProgress as CourseProgressType;
  }
  return { course, courseProgress };
}

function* handleGetCollection(collectionId: string): any {
  // Get course from redux data or fetch it

  // TODO: https://safetyculture.atlassian.net/browse/TRAINING-531
  let collection = yield select(
    (state: LxStoreState) => state.courseware.collections[collectionId]
  );
  if (!collection) {
    yield put(CollectionsActions.fetchCollections({ ids: [collectionId] }));
    const {
      success,
      failure
    }: {
      success: CollectionAction<CollectionsActionTypes.FETCH_COLLECTIONS_SUCCESS>;
      failure: CollectionAction<CollectionsActionTypes.FETCH_COLLECTIONS_FAILURE>;
    } = yield race({
      success: take(CollectionsActionTypes.FETCH_COLLECTIONS_SUCCESS),
      failure: take(CollectionsActionTypes.FETCH_COLLECTIONS_FAILURE)
    });
    if (!!failure) {
      ErrorLogger.captureEvent('Failed Fetching Collection of offline lesson', 'error', {
        failure
      });
      yield put(
        ToastActions.showToast(true, t('dialog.error.message', { ns: 'learners-experience' }))
      );
      return;
    }
    collection = success.payload.items[0];
  }

  return collection;
}

function* downloadProgress(lessonId: string) {
  let progress = 0;
  while (progress < 100) {
    const nextProgress = Math.round(yield);
    if (nextProgress >= progress + PROGRESS_INCREMENT) {
      progress = nextProgress;
      window.__store.dispatch(OfflineActions.downloadLessonOfflineProgress(lessonId, progress));
    }
  }
}

// FETCH LESSONS
function* handleDownloadLesson(
  action: OfflineActionsMap<OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE>
): any {
  const lessonId = action.payload.lessonId;
  // Check if there is already an existing download in progress
  const existingProgress: OfflineType = yield select(
    (state: LxStoreState) => state.offline.status[lessonId]
  );

  if (existingProgress && existingProgress.progress > 0) {
    console.warn(`Attempted to start an exisiting download for lessonId: ${lessonId}`);
    return;
  }
  // Start the download progress for the lesson, and set to 5%, it must be non-zero so that we can detect it
  // is the currently downloading lesson
  const updateProgress = downloadProgress(lessonId);
  updateProgress.next(5);
  let offlineAssets: OfflineAssets | undefined;
  try {
    // Get Ids
    const {
      courseId,
      collectionId,
      playlistId
    }: { courseId: string; collectionId: string; playlistId: string | null } = yield handleGetIds(
      lessonId
    );
    const data: {
      lessonData: {
        lesson: LessonType;
        lessonsProgress: DictionaryType<LessonProgressType>;
      };
      courseData: {
        course: CourseType;
        courseProgress: CourseProgressType;
      };
      collection: CollectionType;
      playlist: PlaylistItemType | undefined;
    } = yield all({
      lessonData: handleGetLessonAndProgress(lessonId),
      courseData: handleGetCourseAndProgress(courseId),
      collection: handleGetCollection(collectionId),
      playlist: handleGetPlaylist(playlistId)
    });
    const { lesson, lessonsProgress } = data.lessonData;
    const { course, courseProgress } = data.courseData;
    const { collection } = data;
    const { playlist } = data;

    itly.lessonDownloaded({
      'Course Name': course.title,
      'Lesson Name': lesson.title
    });

    updateProgress.next(10);

    // Get Assets ready
    offlineAssets = new OfflineAssets(lesson, course, collection, playlist);
    // Download assets
    const downloaded = yield offlineAssets.downloadAssets(progress => {
      // The progress for assets is 70 percent of the downloade
      updateProgress.next(progress * 100 * 0.9 + 10);
    });
    if (!downloaded) {
      throw new Error('Failed to download all lesson assets.');
    }

    // Store offline data
    yield put(
      OfflineActions.saveDataOffline(
        offlineAssets.getOfflineLessonConfiguration(),
        lessonsProgress,
        offlineAssets.getOfflineCourseConfiguration(),
        courseProgress,
        offlineAssets.getOfflineCollectionConfiguration(),
        offlineAssets.getOfflinePlaylistConfiguration()
      )
    );

    // Check Prereq on download
    const items: UnlockPayload[] = yield select<LxStoreState>(
      LessonSelectors.getUnlockPayloadFromPrerequisites(course.lessonSummaries)
    );
    yield put(LessonActions.updateLessonsUnlock(items));

    // Clear this object on success
    offlineAssets = undefined;
  } catch (error) {
    console.error(`An error occured while downloading lesson: ${action.payload.lessonId}`, error);
    yield put(OfflineActions.deleteLessonOffline(lessonId));
  } finally {
    // If this saga was cancelled it will end up in the finally block, so we do out cleanup here
    console.warn('Cleaning up download lesson saga.');
    // Destroy the progress iterator
    if (updateProgress.return) {
      updateProgress.return();
    }
    // Cancel any remaining download operations
    if (offlineAssets) {
      offlineAssets.cancel();
    }

    // Check if all remaining downloads are finished
    const status: DictionaryType<OfflineType> = yield select(
      (state: LxStoreState) => state.offline.status
    );
    const remainingDownloads = Object.values(status).filter(stat => stat.progress < 100);
    if (!remainingDownloads.length) {
      yield put(OfflineActions.allDownloadsCompleted());
    }
  }
}

function* handlePurgeLessonsOffline(
  action: OfflineActionsMap<OfflineActionTypes.PURGE_LESSONS_OFFLINE>
) {
  const status: OfflineState['status'] = yield select(
    (state: LxStoreState) => state.offline.status
  );
  const isOnline: boolean = yield select((state: LxStoreState) => state.config.isOnline);
  const now = new Date();
  // Find lessons that are expired, older than 45 days
  const lessonsToDelete = Object.keys(
    pickBy(status, value => value.expiryDate && new Date(value.expiryDate) < now)
  );

  // Purge lessons that did not finish downloading, usually called on application ready
  // This removes the lessons that have never finished downloading
  // (The process gets killed before downloads ends)
  // Or if you are offline we cancel
  // Also handles the case when you resume and offline
  if (action.payload.purgeDownloadsInProgress || !isOnline) {
    const failedLessons = Object.keys(
      pickBy(status, value => !value.progress || value.progress < 100)
    );
    lessonsToDelete.push(...failedLessons);
  }

  // Check if our offline lessons are still accessible, i.e. have not be removed or unpublished
  if (Object.keys(status).length > 0 && isOnline) {
    yield put(LessonActions.fetchLessonsAccess(Object.keys(status)));
    const { success } = yield race({
      success: take(LessonActionTypes.FETCH_LESSONS_ACCESS_SUCCESS),
      failure: take(LessonActionTypes.FETCH_LESSONS_ACCESS_FAILURE)
    });

    if (success) {
      const { payload } = success as LessonAction<LessonActionTypes.FETCH_LESSONS_ACCESS_SUCCESS>;
      const inaccessible = payload.filter(value => !value.hasAccess).map(v => v.lessonId);
      lessonsToDelete.push(...inaccessible);
    }
  }

  if (lessonsToDelete.length) {
    const deleteEffects = uniq(lessonsToDelete).map(id =>
      put(OfflineActions.deleteLessonOffline(id))
    );
    yield all(deleteEffects);
  }
}

/**
 * Watches the download lesson offline action and initiate the download progress indicator
 * for downloads that are placed in the queue.
 */
function* watchDownloadLessonOfflineQueue() {
  yield takeEvery(OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE, function* (
    action: OfflineActionsMap<OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE>
  ) {
    // Check if the download handler has already started downloading,
    // if that is the case we can skip
    const currentStatus: OfflineType = yield select(
      (state: LxStoreState) => state.offline.status[action.payload.lessonId]
    );
    if (currentStatus && currentStatus.progress > 0) {
      return;
    }
    yield put(OfflineActions.downloadLessonOfflineProgress(action.payload.lessonId, 0));
  });
}

/**
 * Handle the download lesson offline action using a queue mechanism
 */
function* watchDownloadLessonOffline(): any {
  // Create a channel for lesson downloads
  const downloadChannel = yield actionChannel(
    OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE,
    sharedDownloadBuffer
  );
  while (true) {
    // Take from the download queue
    const action: OfflineActionsMap<OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE> = yield take(
      downloadChannel
    );
    if (!action) {
      console.error('action undefined');
      continue;
    }
    // Create a task for the download, so this can be cancelled outside
    try {
      yield race({
        action: call(handleDownloadLesson, action),
        cancel: take(OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE_CANCEL_CURRENT)
      });
    } catch (e) {
      console.error(e);
    }
  }
}

function* watchPurgeLessonsOffline() {
  yield takeLatest(OfflineActionTypes.PURGE_LESSONS_OFFLINE, handlePurgeLessonsOffline);
}

function* watchApplicationResume() {
  yield takeLatest(BackboneActionTypes.APPLICATION_RESUME, function* () {
    yield put(OfflineActions.purgeLessonsOffline(true));
  });
}

function* watchSetOnline() {
  yield takeLatest(ConfigActionTypes.SET_ONLINE, function* (
    action: ConfigActionsMap<ConfigActionTypes.SET_ONLINE>
  ) {
    if (action.payload.online) {
      // If we come online dispatch the following actions
      yield put(OfflineActions.purgeLessonsOffline(false));
    } else {
      // If we go offline purge any downloads
      yield put(OfflineActions.purgeLessonsOffline(true));
    }
  });
}

const offlineSagas = [
  fork(watchDownloadLessonOfflineQueue),
  fork(watchDownloadLessonOffline),
  fork(watchDeleteLessonOffline),
  fork(watchPurgeLessonsOffline),
  fork(watchApplicationResume),
  fork(watchSetOnline)
];

export { offlineSagas };
