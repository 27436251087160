/**
 *
 * <<< MFE (Training) ENTRY FILE >>>
 *
 */
/// <reference types="./theme/styled-components" />
/// <reference types="./cordova/global" />

/**
 * Backbone is required to be available by the app.
 * Most places could be used from an import.
 * However, the communication in the thomas-bridge will run the
 * `Backbone.Events.trigger` in the window and require object to be available.
 */
import * as Backbone from 'backbone';
import { put, race, take } from 'redux-saga/effects';

import type { LoadOptions } from '@edapp/analytics-tracking';
import { ErrorLogger, SentryAdapter } from '@edapp/monitoring';
import type { RelativeToken } from '@edapp/request';
import { initMFELocalization } from '@edapp/translations';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { NetworkMonitor } from '@maggie/core/network-monitor';
import { NewAppRouter } from '@maggie/core/router/router';
import { launchAnalyticTools, launchBrazeTools } from '@maggie/core/vendor-toolings';
import { FeatureFlagsActions } from '@maggie/store/feature-flags/actions';
import rootSaga from '@maggie/store/rootSagas';
import { SessionActionTypes, SessionActions } from '@maggie/store/session/actions';
import { configureStore } from '@maggie/store/store';

import './cordova/types';
import { InteractionApi } from './core/tracker/interaction-api';
import { addOnlineConfigListener } from './store/config/online-config-listener';
import { NavigationActions } from './store/navigation/actions';
import { getLocationPath } from './store/navigation/utils';
import './store/types';
import './theme/theme';
import './types';

export { loadEnv } from '@maggie/config/env';

window.Backbone = Backbone;

function* consumeTokens(tokens: RelativeToken[]): any {
  if (!tokens || tokens.length === 0) {
    ErrorLogger.captureEvent('No tokens available to consume', 'critical', {});
    HostedWebviewUtils.triggerBootstrapError('failed_to_consume_tokens');
    return;
  }

  if (window.__scWebview.platform && !window.__scWebview.isOnline) {
    return; // do not try to start session - the device is offline
  }

  yield put(SessionActions.sessionLogin(tokens, { redirect: false, skipLocaleCheck: true }));
  const { failure } = yield race({
    success: take(SessionActionTypes.SESSION_LOGIN_SUCCESS),
    failure: take(SessionActionTypes.SESSION_LOGIN_FAILURE)
  });

  if (failure) {
    ErrorLogger.captureEvent('Failed to sync user when consuming tokens', 'critical', {
      count: tokens.length,
      min: tokens.map(t => t.value.substring(0, 5))
    });
    HostedWebviewUtils.triggerBootstrapError('failed_to_consume_tokens');
  }
}

export const initialize = async (
  lang: string,
  tokens: RelativeToken[],
  segmentOptions: Partial<LoadOptions>
) => {
  // Error Logger
  ErrorLogger.init(() => new SentryAdapter({ app: 'training' }));

  // Localization
  // !important! When updating the list below, make sure to update the list in:
  //       * `lms-crowdin.yml`: remove the files below from that list
  //       * `lx-crowdin.yml`: add the files below to the list
  await initMFELocalization(lang, [
    'course-translation',
    'ed-components',
    'formio',
    'leaderboard',
    'learners-experience',
    'lesson-navigation',
    'sc-web-ui'
  ]);

  // Segment + Itly
  launchAnalyticTools(segmentOptions);

  // Braze
  launchBrazeTools();

  // Datastore
  const { store, runSaga } = await configureStore();

  addOnlineConfigListener(store);

  window.__networkMonitor = new NetworkMonitor(store);
  window.__router = new NewAppRouter(store, '/training/learn');

  await runSaga(rootSaga);
  await runSaga(consumeTokens, tokens).done; // await for this saga to finish before proceeding

  // Simulate navigation sagas without changing the route
  // In case the app has invalid tokens, there will be a racing condition
  // where it tries to navigate from `api-unauthorized`.
  // If we were to use `window.__router.trigger()` here, it would create an infinite loop
  // where the app would try to navigate and keep failing with unauthorized requests
  const url = getLocationPath(location);
  const m = window.__router.findMatch(url);
  if (m) {
    store.dispatch(NavigationActions.didNavigateRoute(m.routeName, m.params));
  } else {
    store.dispatch(NavigationActions.fallbackDefaultRoute());
  }

  InteractionApi.startupInteractionsApi();

  // Fetch Feature Flags
  store.dispatch(FeatureFlagsActions.fetchFeatureFlags());
};
